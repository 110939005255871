import mro from '../images/projects/mro.png';
import tp from '../images/projects/tp.png'
export const experience = [
    {
      Projects: [
        {
          name: "Target Profilo",
          link: 'https://app.targetprofilo.com/',
          role: 'Full Stack',
          technologies: ['node-js', 'express-js ', 'mongodb', 'Agenda', 'stripe', 'webhook','cron-Job', 'react-js' , 'javaScript'],
          time:'january 24 - ongoing',
          image: tp,
          info: `Target profilo is an Digital marketing Application where user can send email ,whatsapp message ,Sms , and create event , pages, and workflow  and many more `,
        },
        
        {
          name: 'Infospectra',
          link: 'http://103.205.64.43:8080/mro-ui/',
          role: 'React developer',
          technologies:['react-js', 'redux','Syncfusion','javaScript' ],
          image: mro,
          time: 'july 23 - january 24',
          info:`Infospectra is CRM-ERP type of Application that used by the impresa to manage sale and accounts , employe managements and many more .`
        },
        
      ],
    },
  ];