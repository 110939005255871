import { Typography } from '@mui/material'
import React from 'react'

function Blogs() {
    return (
        <Typography>Blogs</Typography>
    )
}

export default Blogs
