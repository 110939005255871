import React from 'react';
import { ProjectsCard } from './ProjectsCard';

function Projects() {

    return (
        <ProjectsCard />
    )
}

export default Projects
